body {
  overflow: hidden;
  background-color: #25D4CD;
}

.App-logo {
  height: auto;
  position: absolute;
  top: 25px;
  left: 25px;
}

.Right-decor {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
  height: auto;
  z-index: -999;
}

.Left-decor {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 50%;
  height: auto;
  z-index: -999;
}

.Insta-follow {
  position: absolute;
  bottom: 20px;
  right: 20px;
  max-width: 50%;
  height: auto;
}

.App-text {
  max-width: 70%;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: 100vh;
}

.button {
  display: block;
}

.button-main {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  height: 75px;
  width: 350px;
  padding: 10px 20px;
  margin-right: 15px;
  margin-top: 25px;
  border-radius: 37.5px;
  border: none;
  white-space: nowrap;
  overflow: hidden;
}

.button-black {
  background-color: #080A09;
  color: #FFFFFF;
}

.button-white {
  background-color: #FFFFFF;
  color: #080A09;
}

.button-main span {
  font-family: allroundgothic-demi;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 10px;
}

.button-main img {
  height: 35px;
  width: 35px;
}

@font-face {
  font-family: allroundgothic-demi;
  src: url(fonts/allroundgothic-demi.otf);
}

@media only screen and (max-width: 767px) {

  .button {
    display: grid;
  }

  .button-main {
    height: 60px;
    width: 300px;
    border-radius: 30px;
  }

  .button-main span {
    font-size: 20px;
  }

  .button-main img {
    height: 30px;
    width: 30px;
  }

}